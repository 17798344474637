.loaderContainer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 100px;
  height: 100px;
  background-image: url('./bc-cart-green-64.png');
  background-repeat: no-repeat;
  background-position: center; 
}
.loaderCircle {
  position:relative;
  z-index: 1000;
  width: 100px;
  height: 100px;
  border: 10px solid rgba(0, 0, 0, 0.2);
  border-left: 10px solid #00b04f;
  border-radius: 50%;  
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
