body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.container {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

