.info {
    padding: 12px 16px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    /* background: white; */
    background: rgba(255, 255, 255,0.8);
    
    border-radius: 5px;
  }
  
  .legend {
    text-align: left;
    line-height: 24px;
    color: #555;
  }
  .circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
  }